import { createSlice } from "@reduxjs/toolkit";

const initialStreamState = {
  stream: {},
  notification: {},
  userInteracted: false,
};

const streamSlice = createSlice({
  name: "stream",
  initialState: initialStreamState,
  reducers: {
    updateStream(state, action) {
      state.stream = action.payload;
    },
    updateNotification(state, action) {
      state.notification = action.payload;
    },
    updateInteracted(state, action) {
      state.userInteracted = action.payload;
    },
  },
});

export const { updateStream, updateNotification, updateInteracted } =
  streamSlice.actions;

export default streamSlice.reducer;
