import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import classes from "./Toast.module.css";
import ToastInnerComponent from "./ToastInnerComponent";

const ToastComponent = () => {
  const messages = useSelector((state) => state.toast.messages);
  return (
    <div className={classes.toastContainer}>
      {messages.map((msg, index) => (
        <ToastInnerComponent key={msg.message + index} msg={msg} />
      ))}
    </div>
  );
};

const Toast = () => {
  return (
    <>
      {ReactDOM.createPortal(
        <ToastComponent />,
        document.getElementById("toast")
      )}
    </>
  );
};

export default Toast;
