import { createSlice } from "@reduxjs/toolkit";

const initialAlertState = {
  district: {},
  panchayat: {},
  mcf: {},
};

const alertSlice = createSlice({
  name: "alert",
  initialState: initialAlertState,
  reducers: {
    updateDistrict(state, action) {
      state.district = action.payload;
    },
    updatePanchayat(state, action) {
      state.panchayat = action.payload;
    },
    updateMcf(state, action) {
      state.mcf = action.payload;
    },
  },
});

export const { updateDistrict, updatePanchayat, updateMcf } =
  alertSlice.actions;

export default alertSlice.reducer;
