import { configureStore } from "@reduxjs/toolkit";
import toastReducer from "./toast";
import streamReducer from "./stream";
import alertReducer from "./alert";
//apis
import { api } from "../services/api";

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    toast: toastReducer,
    stream: streamReducer,
    alert: alertReducer,
  },
  middleware: (gDM) => gDM().concat(api.middleware),
});

export default store;
