import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { showToast, hideToastById } from "../store/toast";
const apiUrl = process.env.REACT_APP_API_BASE_URL;
const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl,
  prepareHeaders: (headers, { getState }) => {
    // if (getState) {
    //   const state = getState();
    //   const token = getToken();
    //   if (token) {
    //     headers.set("Authorization", `Bearer ${token}`);
    //   }
    // }
    return headers;
  },
  responseHandler: (response) => {
    return response.json();
  },
});

const baseQueryWithRefreshToken = async (args, api, extraOptions) => {
  try {
    let result = await baseQuery(args, api, extraOptions);
    if (result?.error) {
      handleError(result, api.dispatch);
      throw new Error("thrownerror");
    }
    api.dispatch(hideToastById(10));
    return result;
  } catch (error) {
    return error;
  }
};

const handleError = (error, dispatchMethod) => {
  console.log(error)
  dispatchMethod(
    showToast({
      message: error?.error?.data?.error || error?.error?.error || error?.error?.data?.message || "Something went wrong",
      type: "error",
      timeout: 5000,
    })
  );
};

export const api = createApi({
  baseQuery: baseQueryWithRefreshToken,
  tagTypes: [],
  onError: (error, api) => {
    handleError(error, api.dispatch);
  },
  endpoints: (builder) => ({}),
});
