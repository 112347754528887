import classes from "./Loading.module.css";
const Loading = ({ theme }) => {
  return (
    <div
      className={`${classes.loading} ${theme == "dark" ? classes.dark : ""}`}
    >
      Loading...
    </div>
  );
};

export default Loading;
