import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { lazy, Suspense } from "react";
import Loading from "./components/Loading/Loading";

import "./App.css";

//define routes here
const Dashboard = lazy(() => import("./pages/dashboard/dashboard"));
const Analytics = lazy(() => import("./pages/Analytics/Analytics"));
const Attendance = lazy(() => import("./pages/Attendance/Attendance"));
const Detection = lazy(() => import("./pages/Detection/Detection"));
const Stream = lazy(() => import("./pages/Stream/Stream"));
const Users = lazy(() => import("./pages/Users/Users"));
const Settings = lazy(() => import("./pages/Settings/Settings"));
const Alerts = lazy(() => import("./pages/Alerts/Alerts"));
const AlertDashboard = lazy(() =>
  import("./pages/AlertDashboard/AlertDashboard")
);

const router = createBrowserRouter([
  {
    path: "",
    element: (
      <Suspense fallback={<Loading />}>
        <Dashboard />
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<Loading />}>
            <Analytics />
          </Suspense>
        ),
      },
      {
        path: "attendance",
        element: (
          <Suspense fallback={<Loading />}>
            <Attendance />
          </Suspense>
        ),
      },
      {
        path: "detection",
        element: (
          <Suspense fallback={<Loading />}>
            <Detection />
          </Suspense>
        ),
      },
      {
        path: "stream",
        element: (
          <Suspense fallback={<Loading />}>
            <Stream />
          </Suspense>
        ),
      },
      {
        path: "users",
        element: (
          <Suspense fallback={<Loading />}>
            <Users />
          </Suspense>
        ),
      },
      {
        path: "alerts",
        element: (
          <Suspense fallback={<Loading />}>
            <Alerts />
          </Suspense>
        ),
      },
      {
        path: "alert-dashboard",
        element: (
          <Suspense fallback={<Loading />}>
            <AlertDashboard />
          </Suspense>
        ),
      },
      {
        path: "settings",
        element: (
          <Suspense fallback={<Loading />}>
            <Settings />
          </Suspense>
        ),
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
